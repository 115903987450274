import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import getFormattedDatetime from '../../../utils/getFormattedDatetime';

const colorByStatus = {
  accepted: '#93BDB8',
  queued: '#93BDB8',
  delivered: '#4CCE99',
  opened: '#1782E4',
  clicked: '#EB9617',
  error: '#DE2929',
};

export default function Delivery({ row, name }) {
  if (!row['mail_update_date']) return null;

  const { status } = row['mail_status'];

  const formattedDate = getFormattedDatetime(row.mail_update_date);

  if (row.is_view === true) {
    return (
      <Container>
        <Circle color={colorByStatus[status]} />
        <Text variant="table-text" isWhite={row.shouldHaveAccentBackground}>
          {formattedDate}
        </Text>
      </Container>
    );
  } else {
    return <></>;
  }
}

const Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '-webkit-align-items': 'center',
  gap: 4,
}));

const Circle = styled(Box)(({ color }) => ({
  height: 8,
  width: 8,
  borderRadius: 10,
  backgroundColor: color,

  transform: 'translateY(-1px)',
}));

const Text = styled(Typography)(({ isWhite }) => ({
  color: isWhite ? 'rgba(255, 255, 255, 0.80)' : 'rgba(27, 47, 43, 0.80)',
  fontFamily: 'Gilroy',
  fontSize: '14px',
  fontWeight: 500,
}));
