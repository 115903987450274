export function getNavLinks(is_staff, localization) {
  return is_staff
    ? [
        {
          url: '/',
          name: 'Requests',
        },
        {
          url: '/statistic/lang',
          name: 'Statictics',
        },
        {
          url: '/analytics',
          name: 'Analytics',
        },
      ]
    : [
        {
          url: '/',
          name: localization.page_requests,
        },
        {
          url: '/statistic/lang',
          name: localization.page_statistics,
        },
      ];
}
