import * as React from 'react';
import { Typography, Stack, Snackbar, styled } from '@mui/material';
import { useRecoilState } from 'recoil';

import Status from './columns/statusButton';
import SnackButton from './snackBarButton';

import theme from '../../theme';
import { rowSelect } from '../atoms/row';
import { getPatchedRows } from './utils/order';
import { ordersAtom } from '../atoms/order';
import { countersAtom } from '../atoms/counters';
import { getCounters } from '../api/counters';

export default function SnackBarTable({ props }) {
  const item = props.length === 1 ? 'item' : 'items';
  const [selectedRows, setSelectedRows] = useRecoilState(rowSelect);
  const [orders, setOrders] = useRecoilState(ordersAtom);
  const [, setCounters] = useRecoilState(countersAtom);

  const handleUpdateOrders = async (item) => {
    let payload = [];

    selectedRows.forEach((row) => {
      payload.push({
        id: row.id,
        status: item,
      });
    });

    getPatchedRows(payload, orders)
      .then((patchedOrders) => {
        setOrders(patchedOrders);
        setSelectedRows([]);
      })
      .catch(console.error);

    getCounters()
      .then((newCounters) => {
        setCounters(newCounters.data);
      })
      .catch(console.error);
  };

  return (
    <Container
      open={props.length === 0 ? false : true}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 2.5,
        }}
      >
        <Typography variant="bold">
          Selected{' '}
          <Typography variant="bold" sx={{ color: theme.palette.green }}>
            {props.length} {item}
          </Typography>
        </Typography>

        <Status
          handleUpdate={handleUpdateOrders}
          buttonElement={SnackButton}
          menuPosition="bottom"
          style={{
            style: {
              left: '50%',
              transform: 'translateX(-17%) translateY(-25%)',
            },
          }}
        />
      </Stack>
    </Container>
  );
}

const Container = styled(Snackbar)(({ theme }) => ({
  borderRadius: 16,
  border: '1px solid rgba(27, 47, 43, 0.10)',
  background: '#F3EFE8',

  padding: '12px 16px',
}));
