import { Button, Chip, styled, Tooltip, Typography } from '@mui/material';
import getFormattedDatetime from '../../utils/getFormattedDatetime';
import Status from './columns/status';
import Device from './columns/device';
import Geolocation from './columns/geolocation';
import IP from './columns/ip';

const StyledButton = styled(Button)(({ isOpen }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2,

  height: 26,
  minWidth: 30,
  padding: 6,
  marginLeft: 4,

  borderRadius: '106px',
  border: '1px solid rgba(16, 190, 118, 0.30)',

  background: 'rgba(16, 190, 118, 0.10)',
}));

const roles = {
  superadmin: 'Admin',
  manager: 'Manager',
  admin: 'Club',
};

function getColumns(clubs, handleSelectClick, isMac) {
  const columns = [
    {
      name: 'id',
      width: '40px',
      cell: (row) => <Typography variant="table-text">{row.user.id}</Typography>,
    },
    {
      name: 'Name',
      cell: (row) => (
        <Typography
          variant="table-text"
          sx={{
            fontWeight: 700,
          }}
        >
          {row.user.first_name}
        </Typography>
      ),
    },
    {
      name: 'Login',
      width: '150px',
      cell: (row) => <Typography variant="table-text">{row.user.username}</Typography>,
    },
    {
      name: 'Role',
      width: '100px',
      cell: (row) => <Typography variant="table-text">{roles[row.role]}</Typography>,
    },
    {
      name: 'Club',
      width: '160px',
      cell: (row) => {
        switch (row.role) {
          case 'superadmin':
            return null;

          case 'manager':
            const clubsNumber = row.clubs.length;
            const managerFirstClub = row.clubs[0];
            return (
              <>
                <Chip
                  label={managerFirstClub?.short_name || managerFirstClub?.name}
                  variant="club"
                  sx={{
                    color: managerFirstClub?.color,
                    border: `1px solid ${managerFirstClub?.color}`,
                    backgroundColor: 'transparent',
                    fontWeight: 1000,
                    height: '26px',
                    maxWidth: 114,
                  }}
                />

                {clubsNumber > 1 && (
                  <Tooltip
                    title={
                      '+ ' +
                      row.clubs
                        .slice(1)
                        .map((club) => club.name)
                        .join(', ')
                    }
                  >
                    <StyledButton>+{clubsNumber - 1}</StyledButton>
                  </Tooltip>
                )}
              </>
            );

          case 'admin':
            const adminClub = row.clubs[0];
            return (
              <Chip
                label={adminClub?.short_name || adminClub?.name}
                variant="club"
                sx={{
                  color: adminClub?.color,
                  border: `1px solid ${adminClub?.color}`,
                  backgroundColor: 'transparent',
                  fontWeight: 1000,
                  height: '26px',
                }}
              />
            );
          default:
            return null;
        }
      },
    },
    {
      name: 'Last Login',
      width: '120px',
      cell: (row) => <Typography variant="table-text">{getFormattedDatetime(row.last_login)}</Typography>,
    },
    {
      name: 'Device',
      width: '80px',
      cell: (row) => <Device row={row} isMac={isMac} />,
    },
    {
      name: 'Geolocation',
      width: '200px',
      cell: (row) => <Geolocation row={row} />,
    },
    {
      name: 'IP',
      width: '120px',
      cell: (row) => <IP row={row} />,
    },
    {
      name: 'Sessions',
      width: '100px',
      cell: (row) => <Typography variant="table-text">{row.sessions}</Typography>,
    },
    {
      name: 'Status',
      width: '90px',
      cell: (row) => <Status handleSelectClick={handleSelectClick} value={row.status} userId={row.user.id} />,
    },
  ];

  return columns;
}

export default getColumns;
