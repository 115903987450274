import { createSearchParams } from 'react-router-dom';
import { getOrderData, patchOrderData } from '../../api/order';
import getFormattedDatetime from '../../../utils/getFormattedDatetime';
import { getStartOfWeek } from './date';

const startOfWeek = getStartOfWeek();

export async function getOrders(queryParams, offset = 0, searchValue, signal) {
  let response;

  // Создаю новые параметры, чтобы offset не появлялся в адресной строке
  const innerQueryParams = createSearchParams(queryParams);
  innerQueryParams.set('offset', offset);

  // Если статус = All - нужны записи со всеми статусами
  if (innerQueryParams.get('status') === 'All') {
    innerQueryParams.delete('status');
  }

  if (searchValue) {
    innerQueryParams.set('search_value', searchValue);
  }

  try {
    response = await getOrderData(innerQueryParams, signal);
  } catch (err) {
    console.log('Ошибка получения данных', err);

    return [[], err];
  }

  let { data: tableData, ...rest } = response.data;
  const id_list = [];
  let lastCreatedDate;
  const isRequestedStatusRegistered = queryParams.get('status') === 'Registered';
  const isRequestedStatusAll = queryParams.get('status') === 'All';

  // create data for grouping and views function on table
  tableData.forEach((row, i) => {
    // sort in id_group
    const id_year_group = tableData.filter((element) => element.id_year === row.id_year);
    row['len_names'] = id_year_group.length;
    row['is_last'] = id_year_group[id_year_group.length - 1].id === row.id;

    row['shouldHaveAccentBackground'] =
      row.status === 'Registered' && isRequestedStatusRegistered && new Date(row.date_visit) < startOfWeek;

    row['shouldHaveBoldTopBorder'] = isRequestedStatusAll && row.created_at.slice(0, 10) !== lastCreatedDate && i !== 0;
    lastCreatedDate = row.created_at.slice(0, 10);

    // is view in table
    if (!id_list.includes(row.id_year)) {
      row['is_view'] = true;
    } else {
      row['is_view'] = false;
    }
    id_list.push(row.id_year);

    // for group_orders_24
    if (row.group_orders_24.length > 0) {
      const id_list_2 = [];
      const peopleInOrdersByOrderId = {};

      row.group_orders_24.forEach((groupOrder) => {
        if (!peopleInOrdersByOrderId[groupOrder.id_year]) {
          peopleInOrdersByOrderId[groupOrder.id_year] = 0;
        }

        peopleInOrdersByOrderId[groupOrder.id_year] += 1;
      });

      row.group_orders_24.forEach((groupOrder, index) => {
        groupOrder['i'] = index;

        if (!id_list_2.includes(groupOrder.id_year)) {
          groupOrder['is_view'] = true;
        } else {
          groupOrder['is_view'] = false;
        }

        peopleInOrdersByOrderId[groupOrder.id_year] -= 1;

        if (peopleInOrdersByOrderId[groupOrder.id_year] === 0) {
          groupOrder['is_last'] = true;

          if (row.group_orders_24.length - 1 !== index) {
            groupOrder['isLastInGroup'] = true;
          }
        }

        groupOrder['shouldHaveAccentBackground'] =
          row.status === 'Registered' && isRequestedStatusRegistered && new Date(row.date_visit) < startOfWeek;

        groupOrder['group_orders_24'] = [];
        id_list_2.push(groupOrder.id_year);
      });
    }
  });

  return [{ newOrders: tableData, ...rest }, null];
}

export const getExpandedOrders = (data) => {
  const ids = new Set();
  let rowsToAdd = [];

  const namesInOrders = data.reduce((acc, row) => {
    if (!acc[row.id_year]) {
      acc[row.id_year] = 0;
    }

    return { ...acc, [row.id_year]: acc[row.id_year] + 1 };
  }, {});

  return data.reduce((acc, row) => {
    namesInOrders[row.id_year] -= 1;

    ids.add(row.email);

    if (namesInOrders[row.id_year] === 0) {
      rowsToAdd = row.group_orders_24.map((row) => {
        const newRow = { ...row };
        newRow.len_names = rowsToAdd.reduce((sum, innerRow) => sum + (innerRow.id_year === row.id_year ? 1 : 0), 0);
        return newRow;
      });

      row.group_orders_24 = [];

      return [...acc, row, ...rowsToAdd];
    } else {
      row.group_orders_24 = [];
      return [...acc, row];
    }
  }, []);
};

export function updatePatchedOrders(patchedOrders, orders) {
  const updatedRowsId = patchedOrders.map((row) => row.id);

  const newOrders = orders.map((order) => {
    const newRow = { ...order };

    if (order.group_orders_24) {
      const newGroupOrders = order.group_orders_24.map((groupOrder) => {
        if (!updatedRowsId.includes(groupOrder.id)) {
          return groupOrder;
        }

        const patchedOrder = patchedOrders.find((row) => row.id === groupOrder.id);

        return {
          ...groupOrder,
          status: patchedOrder.status,
          comment: patchedOrder.comment,
          date_visit: patchedOrder.date_visit,
        };
      });

      newRow.group_orders_24 = newGroupOrders;
    }

    if (!updatedRowsId.includes(newRow.id)) {
      return newRow;
    }

    const patchedOrder = patchedOrders.find((row) => row.id === order.id);

    return {
      ...newRow,
      status: patchedOrder.status,
      comment: patchedOrder.comment,
      date_visit: patchedOrder.date_visit,
    };
  });

  return newOrders;
}

export const getPatchedRows = async (payload, orders) => {
  const response = await patchOrderData(payload);

  if (response.error) {
    console.error('Ошибка обновления статуса заказа');
    console.error(response.error);
    throw new Error(response.error);
  }

  const newOrders = updatePatchedOrders(response.data, orders);

  return newOrders;
};

export const getColoredOrders = (orders, status) => {
  const idList = new Set();
  const dateList = new Set();

  return orders.map((row) => {
    const newRow = { ...row };
    newRow.is_view = !idList.has(newRow.id_year);
    idList.add(newRow.id_year);

    if (row.status === 'Done') {
      dateList.add(getFormattedDatetime(newRow.status_updated_at, true));
    } else {
      dateList.add(getFormattedDatetime(newRow.date_visit, true));
    }

    if (status === 'All') {
      newRow.shouldBeColored = idList.size % 2 === 0;
    } else {
      newRow.shouldBeColored = dateList.size % 2 === 0;
    }

    return newRow;
  });
};
