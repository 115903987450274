import React from 'react';
import { Typography } from '@mui/material';

export default function Geolocation({ row }) {
  if (!row.last_login_IP_info) {
    return null;
  }

  const short = row.last_login_IP_info.short || '';
  const city = row.last_login_IP_info.city || row.last_login_IP_info.region || '';

  let text;

  if (city) {
    text = city + (short ? ', ' + short : '');
  } else {
    text = row.last_login_IP_info.country;
  }

  return <Typography variant="table-text">{text}</Typography>;
}
