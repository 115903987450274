import React from 'react';
import TableOrder from '../../components/table';
import LayOut from '../../components/layOut';

export default function TablePage() {
  return (
    <LayOut>
      <TableOrder />
    </LayOut>
  );
}
