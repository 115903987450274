import React, { useState } from 'react';
import Popup from '../popup';
import { Box, Button, Chip, Typography, styled } from '@mui/material';
import getFormattedDatetime from '../../utils/getFormattedDatetime';

import { ReactComponent as CloseIcon } from '../../static/svg/close.svg';
import { clubsAtom } from '../atoms/clubs';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../atoms/user';
import { localizationAtom } from '../atoms/localization';

const CommentPopup = ({ isOpen, onClose, selectedRow, onCommentChange }) => {
  const clubs = useRecoilValue(clubsAtom);
  const user = useRecoilValue(userAtom);
  const localization = useRecoilValue(localizationAtom);

  const isUserSuperadmin = user?.role === 'superadmin';
  const isUserManager = user?.role === 'manager';

  const [commentValue, setCommentValue] = useState(selectedRow.comment);

  function handleSaveComment() {
    const payload = [
      {
        id: selectedRow.id,
        comment: commentValue,
      },
    ];

    onCommentChange(payload);
    onClose();
  }

  return (
    <Popup
      handleClose={onClose}
      isOpen={isOpen}
      containerStyles={{
        boxSizing: 'border-box',
        width: 578,
        // height: 408,

        padding: 34,
        borderRadius: 34,

        background: '#F3EFE8',

        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1.25,

            // width: 414,
          }}
        >
          {(isUserSuperadmin || isUserManager) && (
            <DataGroup sx={{ width: isUserSuperadmin || isUserManager ? 132 : 202 }}>
              <DataName>Email:</DataName>
              <DataValue>{selectedRow.email}</DataValue>
            </DataGroup>
          )}

          <DataGroup sx={{ width: isUserSuperadmin || isUserManager ? 132 : 202 }}>
            <DataName>{localization['table_visit_date']}:</DataName>
            <DataValue>{getFormattedDatetime(selectedRow.date_visit, true)}</DataValue>
          </DataGroup>

          {clubs && (isUserSuperadmin || isUserManager) && (
            <DataGroup>
              <DataName>Club:</DataName>
              <DataValue>
                <Chip
                  label={clubs.all[selectedRow.club]?.short_name || clubs.all[selectedRow.club]?.name}
                  variant="club"
                  sx={{
                    color: clubs.all[selectedRow.club]?.color,
                    border: `1px solid ${clubs.all[selectedRow.club]?.color}`,
                    background: 'transparent',
                  }}
                />
              </DataValue>
            </DataGroup>
          )}
        </Box>

        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </Box>

      <DataGroup>
        <DataName>{localization['popup_name']}:</DataName>
        <DataValue>{selectedRow.name}</DataValue>
      </DataGroup>

      <DataGroup sx={{ height: '100%' }}>
        <DataName>{localization['popup_comment']}:</DataName>
        <CommentFieldInput value={commentValue} onChange={(evt) => setCommentValue(evt.target.value)} />

        <Button
          onClick={handleSaveComment}
          variant="action"
          sx={{
            fontSize: 16,
            minHeight: 36,
            marginLeft: 'auto',
            padding: '0 24px',
          }}
        >
          {localization['popup_save']}
        </Button>
      </DataGroup>
    </Popup>
  );
};

export default CommentPopup;

const CloseButton = styled(Button)(({ theme }) => ({
  width: '36px',
  height: '36px',

  borderRadius: 18,
  border: '1px solid rgba(27, 47, 43, 0.10)',
  background: 'rgba(243, 239, 232, 0.80)',

  minWidth: 0,
}));

const DataName = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1,
  opacity: 0.6,
}));

const DataValue = styled(DataName)(({ theme }) => ({
  fontWeight: 700,
  opacity: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const DataGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,

  textAlign: 'left',
}));

const CommentFieldInput = styled('textarea')(({ theme }) => ({
  boxSizing: 'border-box',
  resize: 'none',

  width: '100%',
  height: 167,

  padding: '12px',

  borderRadius: '16px',
  border: '1px solid rgba(27, 47, 43, 0.10)',
  outline: 'none',
  background: '#FFF',

  transition: 'all ease .2s',

  color: '#1B2F2B',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '140%',

  '&:hover': {
    border: '1px solid rgba(15, 190, 118, 0.30)',
    boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
  },

  '&:focus': {
    border: '1px solid #0FBE76',
    boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
  },
}));
