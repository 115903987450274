import { Suspense, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ThemeProvider } from '@mui/material/styles';
// import TagManager from 'react-gtm-module'

import { isUserLoggedAtom } from './components/atoms/isUserLogged';
import { userAtom } from './components/atoms/user';
import { clubsAtom } from './components/atoms/clubs';

import './App.css';
import { ReactComponent as LogoOrange } from './static/svg/logo_orange.svg';
import { ReactComponent as LogoGreen } from './static/svg/logo_green.svg';

import Routes from './routes';
import theme from './theme';

import { getClubs } from './components/api/clubs';
import { getUser } from './components/api/user';
import { getCounters } from './components/api/counters';
import { countersAtom } from './components/atoms/counters';
import { localizationAtom } from './components/atoms/localization';
import { localization } from './const/localization';

const Lauding = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {process.env.REACT_APP_PANEL_NAME === 'clubsbcn.online' ? <LogoOrange /> : <LogoGreen />}
    </div>
  );
};

function App() {
  const [user, setUser] = useRecoilState(userAtom);
  const [clubs, setClubs] = useRecoilState(clubsAtom);
  const [, setLocalization] = useRecoilState(localizationAtom);
  const [, setCounters] = useRecoilState(countersAtom);
  const isUserLogged = useRecoilValue(isUserLoggedAtom);

  useEffect(() => {
    if (!isUserLogged) {
      return;
    }

    if (!user || !clubs) {
      getUser()
        .then((user) => {
          setUser(user.data);

          if (['superadmin', 'manager'].includes(user.data.role)) {
            setLocalization(localization['en']);
          } else {
            if (!localStorage.getItem('lang')) {
              localStorage.setItem('lang', 'es');
            }

            const lang = localStorage.getItem('lang');

            setLocalization(localization[lang]);
          }
        })
        .catch((error) => {
          console.log('');
          console.log(error);
        });

      getClubs()
        .then((clubs) => {
          setClubs(clubs.data);
        })
        .catch((error) => {
          console.log('');
          console.log(error);
        });

      getCounters()
        .then((counters) => {
          setCounters(counters.data);
        })
        .catch((error) => {
          console.log('');
          console.log(error);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLogged]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div className="App">
          <Suspense fallback={<Lauding />}>
            <Routes />
          </Suspense>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
