const commonCellsStyle = {
  fontFamily: 'Gilroy',
  fontSize: 12,
  color: '#1B2F2B',

  height: '36px',

  display: 'flex',
  justifyContent: 'center',
  padding: 0,

  border: 'none',
  borderRight: '1px solid rgba(27, 47, 43, 0.10)',
  borderBottom: '1px solid rgba(27, 47, 43, 0.10)',

  '&:last-of-type': {
    borderRight: 'none',
  },

  '@media (max-width: 1440px)': {
    fontSize: 10,
  },
};

const tableStyles = {
  tableWrapper: {
    style: {
      boxSizing: 'border-box',
      overflow: 'hidden',

      width: 1708,

      '@media (max-width: 1440px)': {
        width: 1420,
      },
    },
  },
  table: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  headRow: {
    style: {
      minHeight: '36px',
      height: '36px',

      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  rows: {
    style: {
      minHeight: '36px',
      height: '36px',
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      ...commonCellsStyle,
    },
  },
  headCells: {
    style: {
      ...commonCellsStyle,
      fontWeight: 'bold',
    },
  },
};

export default tableStyles;
