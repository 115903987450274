import axios from 'axios';
import { API_URL } from '../../const/url';
import customHistory from '../../routes/history';

export const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = API_URL;

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('access-token');
    config.headers = {
      Accept: 'application/json',
      ...config.headers,
    };
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Кэш для запросов
// Если уже был отправлен запрос на refresh - то взять результат его выполнения
const refreshCache = {};

// Axios response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refresh-token');

      if (refreshToken) {
        // Send a request to your backend to refresh the access token using the refresh token
        try {
          // Если последний запрос на refresh был более 3 секунд назад - сделать новый Кэш
          // И результатом всех последующие запросов считать результат закэшированного запроса
          if (!refreshCache.time || Date.now() - refreshCache.time > 1000 * 3) {
            const responsePromise = axios.post(`${API_URL}auth/refresh/`, {
              refresh: refreshToken,
            });

            refreshCache.time = Date.now();
            refreshCache.request = responsePromise;

            const response = await responsePromise;

            const newAccessToken = response.data.access;
            const newRefreshToken = response.data.refresh;

            localStorage.setItem('access-token', newAccessToken);
            localStorage.setItem('refresh-token', newRefreshToken);

            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

            return axiosInstance(originalRequest);
          }

          const response = await refreshCache.request;

          const newAccessToken = response.data.access;
          const newRefreshToken = response.data.refresh;

          localStorage.setItem('refresh-token', newRefreshToken);
          localStorage.setItem('access-token', newAccessToken);

          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          localStorage.removeItem('access-token');
          localStorage.removeItem('refresh-token');

          customHistory.push('/login', { state: { form: true } });

          return Promise.reject(refreshError);
        }
      } else {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');

        customHistory.push('/login', { state: { form: true } });

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  },
);
