import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
  FormHelperText,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { axiosInstance } from '../api/axiosInstanse';
import { useLocation } from 'react-router-dom';

const Form = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  margin: '40px 20px',
});

const FieldsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  padding: '25px 30px',
  borderRadius: 10,
  border: '2px solid #7ca84a',
});

const Label = styled(FormLabel)({
  textAlign: 'start',
  marginBottom: 10,
});

const VisitorWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const VisitorBox = styled(Box)({
  padding: '15px 10px',
  borderLeft: '2px solid #7ca84a',
});

export default function MembershipForm() {
  const [comingDate, setComingDate] = useState(null);
  const [email, setEmail] = useState('');
  const [visitorsAmount, setVisitorsAmount] = useState(1);
  const [visitorsNames, setVisitorsNames] = useState(['', '', '', '']);

  const [errorMessage, setErrorMessage] = useState({});
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [serverOkMessage, setServerOkMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery(`(max-width: 1024px)`);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get('utm_source');

  const validateInputs = () => {
    setErrorMessage({});
    let err = false;
    let errObj = {};
    const emailRegEx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!emailRegEx.test(email)) {
      err = true;
      errObj.email = 'Неверный формат почты';
    }
    if (comingDate === null) {
      err = true;
      errObj.date = 'Выберите дату';
    }
    setErrorMessage({ ...errObj });
    return err;
  };
  const handleSend = (e) => {
    e.preventDefault();
    setLoading(true);
    setServerErrorMessage('');
    const err = validateInputs();
    if (err) {
      setLoading(false);
      return;
    }
    setErrorMessage({});
    setServerOkMessage('');
    const utcOffsetHours = dayjs().utcOffset() / 60;
    const cleanNames = visitorsNames.filter((v) => v);
    const formData = {
      date_visit: comingDate.format('DD-MM-YYYY'),
      name: cleanNames,
      email: email,
      type_mobile: isMobile ? 'mobile' : 'desktop',
      referrer: utmSource ?? '',
      utc_offset: utcOffsetHours,

      // TODO: ADD lang_website
      lang_website: '',

      // TODO: CHANGE club_id
      club_id: Math.floor(Math.random() * 6 + 1),
    };
    axiosInstance
      .post(`orders/`, formData)
      .then((res) => {
        setLoading(false);
        setServerOkMessage('Заявка успешно создана!');
      })
      .catch((err) => {
        setLoading(false);
        setServerErrorMessage('Что-то пошло не так!');
      });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleVisitorsNameChange = (e, idx) => {
    if (idx + 1 > visitorsAmount) return;
    setVisitorsNames(
      visitorsNames.map((visitor, index) => {
        if (index === idx) {
          return e.target.value;
        }
        return visitor;
      }),
    );
  };
  return (
    <Form onSubmit={handleSend}>
      <FieldsWrapper>
        <Typography variant="h5" style={{ textAlign: 'start' }}>
          {' '}
          Запрос членства{' '}
        </Typography>
        <FormControl style={{ width: 'max-content' }}>
          <Label>Когда вы планируете прийти?</Label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              minDate={dayjs()}
              value={comingDate}
              format="DD/MM/YYYY"
              onChange={(newValue) => setComingDate(newValue)}
              PaperProps={{ backgroundColor: '#f6f6f6' }}
            />
          </LocalizationProvider>
          <FormHelperText>{errorMessage.date}</FormHelperText>
        </FormControl>

        <FormControl error={errorMessage?.email ? true : false}>
          <Label>Почта</Label>
          <TextField
            placeholder="example@site.com"
            required
            value={email}
            onChange={(e) => handleEmailChange(e)}
            error={errorMessage?.email ? true : false}
          />
          <FormHelperText>{errorMessage.email}</FormHelperText>
        </FormControl>

        <FormControl style={{ width: 'max-content' }} size="small">
          <Label>Количество посетителей</Label>
          <Select
            id="visitors-amount-select"
            value={visitorsAmount}
            onChange={(e) => setVisitorsAmount(e.target.value)}
          >
            <MenuItem value={1}>1 человек</MenuItem>
            <MenuItem value={2}>2 человека</MenuItem>
            <MenuItem value={3}>3 человека</MenuItem>
            <MenuItem value={4}>4 человека</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <Label>Заполните форму, используя латинские символы, как указано в вашем удостоверении личности</Label>
          <VisitorWrapper>
            {Array.from({ length: visitorsAmount }).map((blank, idx) => (
              <VisitorBox key={`visitor-name-${idx}`}>
                <TextField
                  placeholder="фамилия, имя"
                  required
                  value={visitorsNames[idx]}
                  onChange={(e) => handleVisitorsNameChange(e, idx)}
                  fullWidth
                />
              </VisitorBox>
            ))}
          </VisitorWrapper>
        </FormControl>
        {serverErrorMessage && (
          <FormHelperText error={serverErrorMessage ? true : false} size="Normal">
            {serverErrorMessage}
          </FormHelperText>
        )}
        {serverOkMessage && (
          <FormHelperText size="Normal" style={{ color: 'green' }}>
            {serverOkMessage}
          </FormHelperText>
        )}
        <Button type="submit" disabled={loading}>
          Submit
        </Button>
      </FieldsWrapper>
    </Form>
  );
}
