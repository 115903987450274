import React from 'react';
import CustomPopover from './customPopover';
import { Typography, styled } from '@mui/material';
import { langs } from '../../const/lang';

const BarPopover = ({ hoveredBarData = {}, isBarPopoverOpen, ...props }) => {
  const percent = hoveredBarData.percent > 0 ? hoveredBarData.percent : '< 1';

  return (
    <CustomPopover {...props} isOpen={isBarPopoverOpen} bottom={true}>
      <Text>{langs[hoveredBarData.name?.toLowerCase()] || 'Unknown'}</Text>
      <Text sx={{ fontWeight: 700, marginTop: 0.5 }}>{percent}%</Text>
    </CustomPopover>
  );
};

export default BarPopover;

const Text = styled(Typography)(({ theme }) => ({
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
}));
