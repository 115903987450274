import { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography, styled } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../static/svg/close.svg';
import { ReactComponent as PersonIcon } from '../../static/svg/person.svg';
import { ReactComponent as HouseIcon } from '../../static/svg/house.svg';
import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_black.svg';

import Popup from '../popup';
import useFormValidation from '../../utils/useFormValidation';
import theme from '../../theme';
import { useRecoilValue } from 'recoil';
import { clubsAtom } from '../atoms/clubs';
import PasswordButton from '../ui/buttons/passwordButton';

const AddAdminPopup = ({
  isOpen,
  handleClose,
  handleSubmit,
  adminData,
  errorMessage,
  setErrorMessage,
  isEditMode,
  onDelete,
}) => {
  const { handleChange, handleBlur, values, resetForm, isValid, setValues } = useFormValidation();
  const [role, setRole] = useState('admin');
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const clubs = useRecoilValue(clubsAtom);

  function handleToggleClick(evt) {
    if (evt.target.value === 'manager') {
      setValues({
        ...values,
        clubs: role === 'superadmin' || !values.clubs ? [] : [values.clubs],
      });
    } else if (evt.target.value === 'admin') {
      setValues({
        ...values,
        clubs: role === 'superadmin' ? null : values.clubs?.[0] || null,
      });
    } else if (evt.target.value === 'superadmin') {
      setValues({ ...values, clubs: [] });
    }

    setIsDataChanged(true);
    setRole(evt.target.value);
  }

  function handleSelectChange(evt) {
    setIsDataChanged(true);
    handleChange(evt);
  }

  function handleFormSubmit(evt) {
    evt.preventDefault();
    const isUserManager = role === 'manager';
    const isUserAdmin = role === 'admin';

    if (isUserAdmin && !values.clubs) {
      setErrorMessage('Club must be selected');
      return;
    }

    if (isUserManager && values.clubs.length === 0) {
      setErrorMessage('At least one club must be selected');
      return;
    }

    setErrorMessage('');
    const payload = {
      ...values,
      role: role.toLowerCase(),
      clubs: role === 'admin' ? [values.clubs] : values.clubs,
    };
    handleSubmit(payload);
  }

  useEffect(() => {
    setIsDataChanged(false);
  }, [isOpen]);

  useEffect(() => {
    if (adminData) {
      setRole(adminData.role);

      resetForm({
        first_name: adminData.user?.first_name,
        username: adminData.user?.username,
        clubs: adminData.role === 'admin' ? adminData.clubs?.[0]?.pk : adminData.clubs?.map((club) => club.pk),
      });
    } else {
      resetForm();
    }
  }, [resetForm, adminData]);

  if ((isEditMode && !adminData) || !clubs?.all) {
    return null;
  }

  return (
    <Popup
      isOpen={isOpen}
      handleClose={handleClose}
      containerStyles={{
        boxSizing: 'border-box',
        backgroundColor: 'rgba(243, 239, 232, 1)',
        padding: 34,
        borderRadius: 34,
        width: 578,
      }}
    >
      <Header>
        <Typography variant="popup-title">{isEditMode ? 'Edit admin' : 'Create admin'}</Typography>

        <Button variant="circle" sx={{ p: 0 }} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Header>

      <Form action="" onSubmit={handleFormSubmit}>
        <Box>
          <FieldTitle>Name</FieldTitle>

          <Field sx={{ mb: 0.25 }}>
            <PersonIcon />
            <Input
              required
              placeholder="Enter name"
              name="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name || ''}
            />
          </Field>
        </Box>

        <Box>
          <FieldTitle>Login</FieldTitle>

          <Field>
            <PersonIcon />
            <Input
              required
              placeholder="Enter username"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username || ''}
            />
          </Field>
        </Box>

        <Box>
          <FieldTitle>Password</FieldTitle>

          <Field>
            <PasswordButton isPasswordShown={isPasswordShown} onClick={() => setIsPasswordShown(!isPasswordShown)} />
            <Input
              required={!isEditMode}
              minLength={5}
              placeholder="Enter password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password || ''}
              type={isPasswordShown ? 'text' : 'password'}
            />
          </Field>
        </Box>

        <Box>
          <FieldTitle>Role</FieldTitle>

          <Toggles>
            <ToggleButton value="admin" isActive={role === 'admin'} onClick={handleToggleClick}>
              Club
            </ToggleButton>
            <ToggleButton value="manager" isActive={role === 'manager'} onClick={handleToggleClick}>
              Manager
            </ToggleButton>
            <ToggleButton value="superadmin" isActive={role === 'superadmin'} onClick={handleToggleClick}>
              Admin
            </ToggleButton>
          </Toggles>
        </Box>

        {['admin', 'manager'].includes(role) && (
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <FieldTitle>{role === 'manager' ? 'Clubs' : 'Club'}</FieldTitle>
            <CustomSelect
              name="clubs"
              value={values.clubs || []}
              onChange={handleSelectChange}
              IconComponent={ArrowIcon}
              multiple={role === 'manager'}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: 'white',
                    border: '1px solid rgba(27, 47, 43, 0.10)',
                    borderRadius: 4,
                    overflow: 'hidden',

                    '& .MuiMenu-list': {
                      py: 0,
                    },

                    '& .MuiMenuItem-root': {
                      padding: 1.75,
                    },
                  },
                },
              }}
            >
              {Object.entries(clubs?.all).map(([id, { name, color }]) => (
                <MenuItem key={+id} value={+id} name={+id}>
                  <Typography
                    variant={name.toLowerCase()}
                    sx={{
                      fontFamily: 'Gilroy',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 1000,
                      lineHeight: '100%',
                      textTransform: 'uppercase',
                      color: color,
                    }}
                  >
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </CustomSelect>

            <Box
              sx={{
                position: 'absolute',
                left: 12,
                top: 36,
              }}
            >
              <HouseIcon />
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {errorMessage && (
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.red,
                textAlign: 'right',
              }}
            >
              {errorMessage}
            </Typography>
          )}

          <Button
            disabled={!(isValid || isDataChanged)}
            variant="action"
            type="submit"
            sx={{
              width: 82,
            }}
          >
            {isEditMode ? 'Save' : 'Add'}
          </Button>

          {isEditMode && (
            <Button
              variant="action-red"
              onClick={() => onDelete(adminData)}
              sx={{
                width: 82,
              }}
            >
              Delete
            </Button>
          )}
        </Box>
      </Form>
    </Popup>
  );
};

export default AddAdminPopup;

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  marginBottom: 20,
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

const FieldTitle = styled(Typography)(({ theme }) => ({
  color: '#1B2F2B',
  fontFamily: 'Gilroy',
  fontSize: 16,
  fontWeight: 1000,
  lineHeight: 1.4,
  textTransform: 'uppercase',
  textAlign: 'left',

  marginLeft: 10,
  marginBottom: 2,
}));

const Field = styled(Box)(({ theme }) => ({
  height: '48px',
  padding: '0 12px',

  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  border: '1px solid rgba(27, 47, 43, 0.10)',
  borderRadius: '16px',
  background: 'var(--Main-white, #FFF)',

  transition: 'all ease .2s',

  '&:hover': {
    border: '1px solid rgba(15, 190, 118, 0.30)',
    boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
  },

  '&:has(input:focus)': {
    border: '1px solid #0FBE76',
    boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
  },
}));

const Input = styled('input')(({ theme }) => ({
  color: '#434343',
  fontFamily: 'Gilroy',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 1,

  width: '100%',
  height: 30,
  opacity: 0.5,

  border: 'none',
  outline: 'none',

  transition: 'all ease .2s',

  '&:focus, &:not(:placeholder-shown)': {
    opacity: 1,
  },
}));

const Toggles = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 4,
}));

const ToggleButton = styled(Button)(({ isActive }) => ({
  boxSizing: 'border-box',

  width: '100%',
  height: 44,

  borderRadius: 16,
  border: `2px solid ${isActive ? '#1B2F2B' : 'rgba(27, 47, 43, 0.10)'}`,

  backgroundColor: 'white',

  fontFamily: 'Gilroy',
  color: isActive ? '#1B2F2B' : '#434343',
  fontSize: 16,
  textTransform: 'capitalize',

  opacity: isActive ? 1 : 0.5,
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  height: 48,
  padding: '0 12px',

  borderRadius: 16,
  border: '1px solid rgba(27, 47, 43, 0.10)',

  backgroundColor: 'white',
  paddingLeft: 28,

  textAlign: 'left',

  '&:hover': {
    boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
  },

  '&:active': {
    border: '1px solid #0FBE76',
  },
}));
