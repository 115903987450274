import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as DesktopIcon } from '../../../static/svg/desktop.svg';
import { ReactComponent as WhiteDesktopIcon } from '../../../static/svg/desktop_white.svg';
import { ReactComponent as PhoneIcon } from '../../../static/svg/phone.svg';
import { ReactComponent as WhitePhoneIcon } from '../../../static/svg/mobile_white.svg';

export default function TypeScreen({ row, isMac }) {
  const elementByType = {
    mobile: row.shouldHaveAccentBackground ? (
      <WhitePhoneIcon width={isMac ? 20 : 24} />
    ) : (
      <PhoneIcon width={isMac ? 20 : 24} />
    ),
    desktop: row.shouldHaveAccentBackground ? (
      <WhiteDesktopIcon width={isMac ? 20 : 24} />
    ) : (
      <DesktopIcon width={isMac ? 20 : 24} />
    ),
  };

  return row.is_view ? (
    <Box
      sx={{
        opacity: 0.75,
      }}
    >
      {elementByType[row.type_mobile]}
    </Box>
  ) : null;
}
