import React from 'react';
import MembershipForm from '../../components/membershipForm';
import LayOut from '../../components/layOut';

export default function MembershipFormPage() {
  return (
    <LayOut>
      <MembershipForm />
    </LayOut>
  );
}
