export const localization = {
  en: {
    lang: 'en',

    login: 'Login',
    password: 'Password',
    login_placeholder: 'Enter login',
    password_placeholder: 'Enter password',

    page_requests: 'Requests',
    page_statistics: 'Statistics',

    status_expired: 'Expired',
    status_new: 'New',
    status_registered: 'Registered',

    counter_today: 'Today',
    counter_total: 'Total',
    counter_this_week: 'This week',
    counter_last_week: 'Last week',

    placeholder: 'Enter name or request №',

    table_created: 'Created',
    table_visitors: 'Visitors',
    table_visit_date: 'Visit date',
    table_status: 'Status',
    table_lang: 'Lang',
    table_IP: 'Country',
    table_comment: 'Comment',
    table_end_message: "That's all",

    popup_name: 'Visitor Name',
    popup_comment: 'Comment',
    popup_save: 'Save',

    year: 'Year',
    month: 'Month',
    all: 'All',
    month_january: 'January',
    month_february: 'February',
    month_march: 'March',
    month_april: 'April',
    month_may: 'May',
    month_june: 'June',
    month_july: 'July',
    month_august: 'August',
    month_september: 'September',
    month_october: 'October',
    month_november: 'November',
    month_december: 'December',
  },
  ru: {
    lang: 'ru',

    login: 'Логин',
    password: 'Пароль',
    login_placeholder: 'Введите логин',
    password_placeholder: 'Введите пароль',

    page_requests: 'Заявки',
    page_statistics: 'Статистика',

    status_expired: 'Истёкший',
    status_new: 'Новый',
    status_registered: 'Зарегистрирован',

    counter_today: 'Сегодня',
    counter_total: 'Всего',
    counter_this_week: 'На этой неделе',
    counter_last_week: 'На прошлой неделе',

    placeholder: 'Введите имя или номер зявки',

    table_created: 'Создан',
    table_visitors: 'Посетители',
    table_visit_date: 'Дата посещения',
    table_status: 'Статус',
    table_lang: 'Язык',
    table_IP: 'Страна',
    table_comment: 'Комментарий',
    table_end_message: 'Это всё',

    popup_name: 'Имя посетителя',
    popup_comment: 'Комментарий',
    popup_save: 'Сохранить',

    year: 'Год',
    month: 'Месяц',
    all: 'Все',
    month_january: 'Январь',
    month_february: 'Февраль',
    month_march: 'Март',
    month_april: 'Апрель',
    month_may: 'Май',
    month_june: 'Июнь',
    month_july: 'Июль',
    month_august: 'Август',
    month_september: 'Сентябрь',
    month_october: 'Октябрь',
    month_november: 'Ноябрь',
    month_december: 'Декабрь',
  },
  it: {
    lang: 'it',

    login: 'Login',
    password: 'Password',
    login_placeholder: 'Inserisci il login',
    password_placeholder: 'Inserisci la password',

    page_requests: 'Richieste',
    page_statistics: 'Statistiche',

    status_expired: 'Scaduto',
    status_new: 'Nuovo',
    status_registered: 'Registrato',

    counter_today: 'Oggi',
    counter_total: 'Totale',
    counter_this_week: 'Questa settimana',
    counter_last_week: 'Settimana scorsa',

    placeholder: 'Inserire nome e № della richiesta',

    table_created: 'Creato',
    table_visitors: 'Visitatori',
    table_visit_date: 'Data visita',
    table_status: 'Stato',
    table_lang: 'Lingua',
    table_IP: 'Paese',
    table_comment: 'Commento',
    table_end_message: 'È tutto',

    popup_name: 'Nome visitatore',
    popup_comment: 'Commento',
    popup_save: 'Mantenere',

    year: 'Anno',
    month: 'Mese',
    all: 'Tutti',
    month_january: 'Gennaio',
    month_february: 'Febbraio',
    month_march: 'Marzo',
    month_april: 'Aprile',
    month_may: 'Maggio',
    month_june: 'Giugno',
    month_july: 'Luglio',
    month_august: 'Agosto',
    month_september: 'Settembre',
    month_october: 'Ottobre',
    month_november: 'Novembre',
    month_december: 'Dicembre',
  },
  es: {
    lang: 'es',

    login: 'Iniciar sesión',
    password: 'Contraseña',
    login_placeholder: 'Ingrese el nombre de usuario',
    password_placeholder: 'Ingrese la contraseña',

    page_requests: 'Solicitudes',
    page_statistics: 'Estadísticas',

    status_expired: 'Caducado',
    status_new: 'Nuevo',
    status_registered: 'Registrado',

    counter_today: 'Hoy',
    counter_total: 'Total',
    counter_this_week: 'Esta semana',
    counter_last_week: 'Semana pasada',

    placeholder: 'Introduzca el nombre o № de solicitud',

    table_created: 'Creado',
    table_visitors: 'Visitantes',
    table_visit_date: 'Fecha de visita',
    table_status: 'Estado',
    table_lang: 'Idioma',
    table_IP: 'País',
    table_comment: 'Comentario',
    table_end_message: 'Eso es todo',

    popup_name: 'Nombre del visitante',
    popup_comment: 'Comentario',
    popup_save: 'Guardar',

    year: 'Año',
    month: 'Mes',
    all: 'Todos',
    month_january: 'Enero',
    month_february: 'Febrero',
    month_march: 'Marzo',
    month_april: 'Abril',
    month_may: 'Mayo',
    month_june: 'Junio',
    month_july: 'Julio',
    month_august: 'Agosto',
    month_september: 'Septiembre',
    month_october: 'Octubre',
    month_november: 'Noviembre',
    month_december: 'Diciembre',
  },
};

export const languages = [
  { shortName: 'en', name: 'English' },
  { shortName: 'es', name: 'Spanish' },
  { shortName: 'it', name: 'Italian' },
  { shortName: 'ru', name: 'Russian' },
];
