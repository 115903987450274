import { Box } from '@mui/material';

const CustomPopover = ({ isOpen, children, bottom = false }) => {
  return (
    <Box
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',

        position: 'relative',
        overflow: 'visible',
        zIndex: 2,

        fontSize: '14px',
        fontWeight: 700,
        lineHeight: 'normal',

        width: 'fit-content',
        padding: '4px 8px',
        marginBottom: 4,
        borderRadius: 2,

        backgroundColor: '#1B2F2B',
        color: 'white',
        opacity: isOpen ? 1 : 0,

        transition: 'opacity .1s ease-in-out',

        transform: 'translateX(-50%)',

        '&::before': {
          content: '""',
          display: 'block',
          backgroundColor: '#1B2F2B',
          height: 10,
          width: 10,
          zIndex: 1,

          borderRadius: 0.5,

          position: 'absolute',
          top: bottom ? 'unset' : 0,
          bottom: bottom ? '-20%' : 'unset',
          left: '50%',

          transform: `rotate(45deg) translateX(-50%)`,
        },
      }}
      // open={active}
      // anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: 'top',
      //   horizontal: 'center',
      // }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'center',
      // }}
      // disableRestoreFocus
    >
      {children}
    </Box>
  );
};

export default CustomPopover;
