import React, { useEffect, useMemo, useState } from 'react';
import { Box, Container, Menu, MenuItem, styled, Typography, useMediaQuery } from '@mui/material';
import DataTable from 'react-data-table-component';
import { useRecoilValue } from 'recoil';

import { CreateAdmin, DeleteAdmin, UpdateAdmin, getAdmins } from '../api/admins';
import { tableStyles } from './styles/tableStyles';
import { clubsAtom } from '../atoms/clubs';
import getColumns from './columns';

import NoData from './NoData';
import Header from './header';
import AdminPopup from './AdminPopup';
import { userAtom } from '../atoms/user';
import customHistory from '../../routes/history';

const Admins = () => {
  const clubs = useRecoilValue(clubsAtom);
  const user = useRecoilValue(userAtom);
  const [admins, setAdmins] = useState([]);

  const isMac = useMediaQuery('(max-width: 1440px)');

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [addPopupErrorMessage, setAddPopupErrorMessage] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [editAdminData, setEditAdminData] = useState({});

  const [lastUserClickedStatusButton, setLastUserClickedStatusButton] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleSelectClick = (event) => {
    const userId = event.target.closest('#basic-status-button').getAttribute('data-userid');

    setLastUserClickedStatusButton(userId);
    setAnchorEl(event.currentTarget);
  };

  const columns = useMemo(() => getColumns(clubs?.all, handleSelectClick, isMac), [clubs, isMac]);

  const handleSelectChange = (status) => {
    UpdateAdmin({ status, id: lastUserClickedStatusButton })
      .then((response) => {
        const updatedUserId = response.data.user.id;

        setAdmins((prev) => prev.map((admin) => (admin.user.id === updatedUserId ? response.data : admin)));
      })
      .catch((error) => {
        console.log('Ошибка обновления данных админа');
      });
    handleSelectClose();
  };

  const handleSelectClose = () => {
    setAnchorEl(null);
  };

  function handleAddButtonClick() {
    setIsPopupOpen(true);
    setIsEditMode(false);
  }

  function handleCloseAddPopup() {
    setIsPopupOpen(false);
  }

  function handleSubmitAddPopup(payload) {
    if (isEditMode) {
      payload.id = editAdminData.user.id;

      UpdateAdmin(payload)
        .then((response) => {
          const updatedUserId = response.data.user.id;

          setIsPopupOpen(false);
          setAddPopupErrorMessage('');
          setAdmins((prev) => prev.map((admin) => (admin.user.id === updatedUserId ? response.data : admin)));
        })
        .catch((error) => {
          console.log(error);
          setAddPopupErrorMessage(error.response.data.message || 'Error');
        });
    } else {
      CreateAdmin(payload)
        .then((response) => {
          setIsPopupOpen(false);
          setAddPopupErrorMessage('');

          setAdmins((prev) => [...prev, response.data]);
        })
        .catch((error) => {
          console.log(error);
          setAddPopupErrorMessage(error.response.data.message || 'Error');
        });
    }
  }

  function handleDeleteButtonClick(admin) {
    DeleteAdmin({ id: admin.user.id })
      .then(() => {
        setAdmins((prev) => prev.filter((prevAdmin) => prevAdmin.user.id !== admin.user.id));
        setIsPopupOpen(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(() => {
    getAdmins()
      .then((res) => {
        setAdmins(res.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении админов');
      });
  }, []);

  useEffect(() => {
    function handleClick(evt) {
      if (evt.target.closest('#basic-status-button')) {
        return;
      }

      const rowElement = evt.target.closest('[role=row]');

      if (!rowElement) {
        return;
      }

      const userIndex = rowElement.id.split('-')[1];
      const user = admins[userIndex];

      setIsPopupOpen(true);
      setIsEditMode(true);
      setEditAdminData({ user: user.user, clubs: user.clubs, role: user.role });
    }

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [admins]);

  useEffect(() => {
    if (user && user.role !== 'superadmin') {
      customHistory.push('/');
    }
  }, [user]);

  return (
    <>
      <Container>
        <Header handleAddButtonClick={handleAddButtonClick} />
      </Container>

      <TableWrapper>
        <DataTable columns={columns} data={admins} customStyles={tableStyles} noDataComponent={<NoData />} />
      </TableWrapper>

      <AdminPopup
        isOpen={isPopupOpen}
        handleClose={handleCloseAddPopup}
        handleSubmit={handleSubmitAddPopup}
        errorMessage={addPopupErrorMessage}
        isEditMode={isEditMode}
        adminData={isEditMode ? editAdminData : null}
        onDelete={handleDeleteButtonClick}
        setErrorMessage={setAddPopupErrorMessage}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleSelectClose}
        sx={{
          marginTop: 0.25,

          '& .MuiMenu-paper': {
            borderRadius: 4,
            border: '1px solid rgba(27, 47, 43, 0.10)',
            background: '#FFF',
            boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.05)',
          },

          '& .MuiList-root': {
            padding: 0,
          },
        }}
      >
        {['active', 'blocked'].map((status) => {
          return (
            <MenuItem
              key={status}
              onClick={() => handleSelectChange(status)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '12px 14px',
                width: 250,
                borderBottom: '1px solid rgba(27, 47, 43, 0.10)',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 1000,
                  lineHeight: 1,
                  textTransform: 'uppercase',
                }}
              >
                {status}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default Admins;

const TableWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',

  maxWidth: 1300,
  margin: '0 auto',
}));
