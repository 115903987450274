export const commonGraphBackgroundStyles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
};

export const commonTableBackgroundStyles = {
  position: 'absolute',
  top: 0,
  height: 36,
};
