import { getYears } from '../utils/date';

export const yearVariants = getYears();
export const monthVariants = [
  'all',
  'month_january',
  'month_february',
  'month_march',
  'month_april',
  'month_may',
  'month_june',
  'month_july',
  'month_august',
  'month_september',
  'month_october',
  'month_november',
  'month_december',
];
