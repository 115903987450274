import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CartesianGrid, XAxis, Bar, BarChart, LabelList, PieChart, Pie, Cell, Legend, Tooltip, YAxis } from 'recharts';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';

import { getStats } from '../api/statistic';
import { getSumOfValuesByMonth, transformStatsForRechart } from './utils/stats';
import { colorsByCountry, months, statisticsFilters } from './consts';
import { localizationAtom } from '../atoms/localization';
import { userAtom } from '../atoms/user';

import Header from '../header';
import StatisticHeader from './statisticHeader';
import BarPopover from './barPopover';
import PiePopover from './piePopover';
import { BAR_COLOR, BAR_COLOR_REVERSED, BAR_WEEKEND_COLOR } from '../../const/colors';
import { monthVariants } from '../../const/year';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import usePolling from '../../hooks/usePolling';

const StatisticPage = () => {
  const isMac = useMediaQuery('(max-width: 1440px)');

  const localization = useRecoilValue(localizationAtom);
  const user = useRecoilValue(userAtom);

  const isUserSuperadmin = useMemo(() => (user?.role === 'superadmin'), [user]);
  const isUserManager = useMemo(() => (user?.role === 'manager'), [user]);

  const { height } = useWindowDimensions();
  const graphHeight = useMemo(() => (Math.max((height - 46 - 56 - 82 - 24 - 24 - 20) / 2, 260)), [height]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [stats, setStats] = useState({});
  const [counters, setCounters] = useState({});
  const [sumOfValues, setSumOfValues] = useState({});
  const [statisticsFilter, setStatisticsFilter] = useState(Object.fromEntries(Array(12).fill().map((_, index) => ([index, statisticsFilters[0]]))));

  const [isBarPopoverOpen, setIsBarPopoverOpen] = useState(false);
  const [hoveredBarData, setHoveredBarData] = useState({});

  const [isPiePopoverOpen, setIsPiePopoverOpen] = useState({});
  const [hoveredSectorData, setHoveredSectorData] = useState({});

  const [currentClubFilter, setCurrentClubFilter] = useState('all');

  const [month, setMonth] = useState(monthVariants[new Date().getMonth() + 1]);
  const year = searchParams.get('year') || new Date().getFullYear();

  const monthNumber = useMemo(() => (monthVariants.indexOf(month)), [month]);
  const statsDataToRender = useMemo(() => {
    return (monthNumber === 0)
      ? Object.entries(stats?.[currentClubFilter] || {}).sort((a, b) => a[0] - b[0])
      : Object.entries(stats?.[currentClubFilter] || {}).filter((monthData) => monthData[0] === String(monthNumber));
  }, [monthNumber, currentClubFilter, stats]);

  function updateFilter(newFilters) {
    setSearchParams((prev) => {
      const curr = createSearchParams(prev);

      Object.entries(newFilters).forEach(([key, value]) => {
        if (value !== null) {
          curr.set(key, value);
        } else {
          curr.delete(key);
        }
      });

      return curr;
    });
  }

  function handleMouseOverBar(evt, monthData, countryName, monthNumber) {
    const currentBarValue = monthData.lang_browser.find((barData) => barData.name === countryName).value;

    setIsBarPopoverOpen(true);
    setHoveredBarData({
      name: countryName,
      value: currentBarValue,
      percent: Math.round((currentBarValue / sumOfValues[currentClubFilter][monthNumber].lang_browser) * 100),
    });
  }

  function handleMouseOutBar() {
    setIsBarPopoverOpen(false);
  }

  function handleFilterChange(monthIndex, filterName) {
    setStatisticsFilter((prev) => {
      const newStatisticsFilter = { ...prev };
      newStatisticsFilter[monthIndex] = filterName;
      return newStatisticsFilter;
    });
  }

  function handleMouseOverPie(evt, monthData, countryName, monthNumber) {
    const hoveredPieValue = monthData.lang_website.find((barData) => barData.name === countryName).value;

    setIsPiePopoverOpen((prev) => {
      const newState = { ...prev };
      newState[monthNumber] = true;
      return newState;
    });
    setHoveredSectorData({
      name: countryName,
      number: hoveredPieValue,
      value: Math.round((hoveredPieValue / sumOfValues[currentClubFilter][monthNumber].lang_browser) * 100),
    });
  }

  function handleMouseOutPie(monthNumber) {
    setIsPiePopoverOpen((prev) => {
      const newState = { ...prev };
      newState[monthNumber] = false;
      return newState;
    });
  }

  const fetchStatistics = () => {
    getStats(searchParams)
      .then(({ data }) => {
        const { orders } = data;

        const transformedOrdersData = Object.entries(orders).reduce((acc, [key, value]) => {
          acc[key] = transformStatsForRechart(value, year);
          return acc;
        }, {});

        const sumOfOrdersValuesData = Object.entries(transformedOrdersData).reduce((acc, [key, value]) => {
          acc[key] = getSumOfValuesByMonth(value);
          return acc;
        }, {});

        const counters = Object.entries(orders).reduce((acc, [clubID, value]) => {
          const counters = Object.entries(value).reduce((acc, [month, monthData]) => {
            return {
              ...acc,
              [month]: { ...monthData.counters, avg: monthData.avg },
            };
          }, {});

          acc[clubID] = counters;
          return acc;
        }, {});

        setCounters(counters);
        setStats(transformedOrdersData);
        setSumOfValues(sumOfOrdersValuesData);
      })
      .catch((err) => {
        console.log('Ошибка получения данных', err);
      });
  };

  usePolling(fetchStatistics);

  useEffect(() => {
    setCurrentClubFilter(isUserSuperadmin || isUserManager ? searchParams.get('club') || 'all' : user?.clubs[0].pk);

    if (user) {
      fetchStatistics(true);
    }
  }, [searchParams, user]);

  useEffect(() => {
    if (user?.role === 'manager') {
      setSearchParams((prev) => {
        const curr = createSearchParams(prev);
        curr.set('club', user.clubs[0].pk);
        return curr;
      });
    }
  }, [user]);

  return (
    <Container>
      <Header updateFilter={updateFilter} searchParams={searchParams} />

      <StatisticHeader updateFilter={updateFilter} searchParams={searchParams} setMonth={setMonth} month={month} />

      <StatsContainer>
        {statsDataToRender?.length > 0 ? (
          statsDataToRender.map(([monthNumber, data]) => (
            <MonthContainer key={monthNumber}>
              <MonthHeader>
                <LeftContainer>
                  <MonthName variant="bold" isMac={isMac}>
                    {localization[`month_${months[monthNumber - 1].toLowerCase()}`]}
                  </MonthName>

                  <Toggle>
                    {statisticsFilters.map((filter) => (
                      <ToggleItem
                        key={filter}
                        onClick={() => handleFilterChange(monthNumber - 1, filter)}
                        isActive={statisticsFilter[monthNumber - 1] === filter}
                        activeColor={BAR_COLOR}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="bold"
                          sx={{
                            fontSize: 14,
                            color: 'unset',
                          }}
                        >
                          {filter}
                        </Typography>
                      </ToggleItem>
                    ))}
                  </Toggle>
                </LeftContainer>

                {(isUserSuperadmin || isUserManager) && (
                  <CounterGroup>
                    <Counter>
                      <Typography
                        variant="bold"
                        sx={{
                          fontSize: 14,
                          color: '#1B2F2B',
                        }}
                      >
                        VT
                      </Typography>

                      <Typography sx={{ fontSize: 14 }}>
                        {counters[currentClubFilter]?.[monthNumber]?.unique_orders_this_month_today_visit || 0}%
                      </Typography>
                    </Counter>

                    <Counter>
                      <Typography
                        variant="bold"
                        sx={{
                          fontSize: 14,
                          color: '#1B2F2B',
                        }}
                      >
                        AVG
                      </Typography>

                      <Typography sx={{ fontSize: 14 }}>
                        {counters[currentClubFilter]?.[monthNumber]?.avg.by_created || 0}
                      </Typography>
                    </Counter>

                    <Counter>
                      <Typography
                        variant="bold"
                        sx={{
                          fontSize: 14,
                          color: '#1B2F2B',
                        }}
                      >
                        IP ES
                      </Typography>

                      <Typography sx={{ fontSize: 14 }}>
                        {counters[currentClubFilter]?.[monthNumber]?.unique_orders_this_month_IP_ES || 0}%
                      </Typography>
                    </Counter>
                  </CounterGroup>
                )}

                <RightContainer />
              </MonthHeader>

              <MonthBody>
                {(isUserSuperadmin || isUserManager) && (
                  <BarChart
                    width={isMac ? 1374 : 1660}
                    height={graphHeight}
                    data={data.days}
                    style={{
                      boxSizing: 'border-box',
                      borderRadius: 6,
                      border: '1px solid rgba(27, 47, 43, 0.10)',
                    }}
                  >
                    <CartesianGrid stroke="rgba(27, 47, 43, 0.10)" vertical={false} />
                    <XAxis
                      dataKey="name"
                      height={30}
                      strokeWidth="0"
                      style={{
                        fill: '#1B2F2B',
                        fontFamily: 'Gilroy',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                      }}
                    />
                    <YAxis width={0} domain={[0, (dataMax) => dataMax * 1.15]} />

                    <Bar
                      dataKey={`by_created_${statisticsFilter[monthNumber - 1]}`}
                      layout="horizontal"
                      maxBarSize={80}
                    >
                      {data.days.map((entry) => (
                        <Cell
                          radius={5}
                          key={`cell-${currentClubFilter}-${entry.name}`}
                          fill={
                            [0, 6].includes(new Date(year, monthNumber - 1, entry.name).getDay())
                              ? BAR_WEEKEND_COLOR
                              : BAR_COLOR
                          }
                          style={{
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                          // onMouseEnter={(evt) => handleMouseOverDaysBar(evt, entry, monthNumber)}
                          // onMouseOut={handleMouseOutDaysBar}
                        />
                      ))}

                      <LabelList
                        dataKey={`by_created_${statisticsFilter[monthNumber - 1]}`}
                        position="top"
                        formatter={(value) => (value === 0 ? null : value)}
                        style={{
                          fill: '#1B2F2B',
                          fontFamily: 'Gilroy',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '100%',
                        }}
                      />
                    </Bar>
                  </BarChart>
                )}

                <BarChart
                  width={isMac ? 1374 : 1660}
                  height={graphHeight}
                  data={data.days}
                  style={{
                    boxSizing: 'border-box',
                    borderRadius: 6,
                    border: '1px solid rgba(27, 47, 43, 0.10)',
                  }}
                >
                  <CartesianGrid stroke="rgba(27, 47, 43, 0.10)" vertical={false} />
                  <XAxis
                    dataKey="name"
                    height={30}
                    strokeWidth="0"
                    style={{
                      fill: '#1B2F2B',
                      fontFamily: 'Gilroy',
                      fontSize: '10px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                    }}
                  />
                  <YAxis width={0} domain={[0, (dataMax) => dataMax * 1.15]} />

                  <Bar dataKey={`by_visit_${statisticsFilter[monthNumber - 1]}`} layout="horizontal" maxBarSize={80}>
                    {data.days.map((entry) => (
                      <Cell
                        radius={5}
                        key={`cell-${currentClubFilter}-${entry.name}`}
                        fill={
                          [0, 6].includes(new Date(year, monthNumber - 1, entry.name).getDay())
                            ? BAR_WEEKEND_COLOR
                            : BAR_COLOR_REVERSED
                        }
                        style={{
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        // onMouseEnter={(evt) => handleMouseOverDaysBar(evt, entry, monthNumber)}
                        // onMouseOut={handleMouseOutDaysBar}
                      />
                    ))}

                    <LabelList
                      dataKey={`by_visit_${statisticsFilter[monthNumber - 1]}`}
                      position="top"
                      formatter={(value) => (value === 0 ? null : value)}
                      style={{
                        fill: '#1B2F2B',
                        fontFamily: 'Gilroy',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '100%',
                      }}
                    />
                  </Bar>
                </BarChart>

                <BottomGraphs>
                  <BarChart
                    width={isUserSuperadmin || isUserManager ? 1000 : isMac ? 1374 : 1660}
                    height={graphHeight}
                    data={data.lang_browser}
                    style={{
                      boxSizing: 'border-box',
                      borderRadius: 6,
                      border: '1px solid rgba(27, 47, 43, 0.10)',
                    }}
                  >
                    <CartesianGrid stroke="rgba(27, 47, 43, 0.10)" vertical={false} />
                    <XAxis
                      dataKey="name"
                      height={30}
                      strokeWidth="0"
                      style={{
                        fill: '#1B2F2B',
                        fontFamily: 'Gilroy',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                      }}
                    />
                    <YAxis width={0} domain={[0, (dataMax) => dataMax * 1.15]} />
                    <Tooltip
                      content={<BarPopover hoveredBarData={hoveredBarData} isBarPopoverOpen={isBarPopoverOpen} />}
                      isAnimationActive={false}
                      cursor={false}
                      offset={0}
                      reverseDirection={{
                        y: true,
                      }}
                      allowEscapeViewBox={{
                        x: true,
                        y: true,
                      }}
                    />

                    <Bar dataKey="value" layout="horizontal" maxBarSize={80}>
                      {data.lang_browser.map((entry) => (
                        <Cell
                          radius={5}
                          key={`cell-${entry.name}`}
                          fill={colorsByCountry[entry.name.toUpperCase()] || '#8884d8'}
                          style={{
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                          onMouseEnter={(evt) => handleMouseOverBar(evt, data, entry.name, monthNumber)}
                          onMouseOut={handleMouseOutBar}
                        />
                      ))}

                      <LabelList
                        dataKey="value"
                        position="top"
                        style={{
                          fill: '#1B2F2B',
                          fontFamily: 'Gilroy',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '100%',
                        }}
                      />
                    </Bar>
                  </BarChart>

                  {(isUserSuperadmin || isUserManager) && (
                    <Box
                      sx={{
                        borderRadius: 2,
                        border: '1px solid rgba(27, 47, 43, 0.10)',
                        paddingBottom: 2.75,
                      }}
                    >
                      <PieChart
                        width={isMac ? 348 : 636}
                        height={graphHeight - 24}
                        style={{
                          boxSizing: 'border-box',

                          color: '#1B2F2B',
                          fontFamily: 'Gilroy',
                          fontSize: '10px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                        }}
                        onMouseOut={() => handleMouseOutPie(monthNumber)}
                      >
                        <Pie
                          data={data.lang_website}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          legendType="circle"
                          outerRadius={graphHeight / 2 - 30}
                          startAngle={90}
                          endAngle={450}
                        >
                          {data.lang_website.map((entry) => (
                            <Cell
                              key={`cell-${entry.name}`}
                              fill={colorsByCountry[entry.name.toUpperCase()] || '#8884d8'}
                              style={{
                                cursor: 'pointer',
                              }}
                              onMouseOver={(evt) => handleMouseOverPie(evt, data, entry.name, monthNumber)}
                            />
                          ))}
                        </Pie>
                        <Legend formatter={(value) => <span style={{ color: '#1B2F2B' }}>{value}</span>} />

                        <Tooltip
                          content={
                            <PiePopover
                              hoveredSectorData={hoveredSectorData}
                              isPiePopoverOpen={isPiePopoverOpen[monthNumber]}
                            />
                          }
                          animationDuration={50}
                          allowEscapeViewBox={{
                            x: true,
                            y: true,
                          }}
                        />
                      </PieChart>
                    </Box>
                  )}
                </BottomGraphs>
              </MonthBody>
            </MonthContainer>
          ))
        ) : (
          <p>Нет данных</p>
        )}
      </StatsContainer>
    </Container>
  );
};

export default StatisticPage;

const Container = styled(Box)(({ theme }) => ({
  maxWidth: 1728,
  width: '100%',
  margin: '0 auto 20px',
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px 10px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
}));

const LeftContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,

  minWidth: 250,
}));

const RightContainer = styled(Box)(() => ({
  minWidth: 250,
}));

const Filters = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
}));

const CounterGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '34px',
}));

const Counter = styled(Box)(({ isColored }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',

  height: '100%',
  padding: '0px 12px',

  backgroundColor: isColored ? '#368362' : 'transparent',
  color: isColored ? 'white' : 'inherit',

  border: '1px solid rgba(27, 47, 43, 0.10)',

  '&:not(:last-child)': {
    borderRight: 'none',
  },

  '&:first-child': {
    borderRadius: '16px 0 0 16px',
  },

  '&:last-child': {
    borderRadius: '0 16px 16px 0',
  },
}));

export const MonthName = styled(Typography)(({ isMac }) => ({
  minWidth: isMac ? 131 : 158,
}));

export const Toggle = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '34px',
}));

export const ToggleItem = styled(Box)(({ isActive, activeColor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',

  height: '100%',
  padding: '0px 12px',

  backgroundColor: isActive ? activeColor + '10' : 'transparent',
  color: isActive ? activeColor : 'inherit',

  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: isActive ? activeColor + 'aa' : '#ccc',

  cursor: 'pointer',
  transition: 'background-color .2s, border-color .2s, color .2s',

  '&:hover': {
    backgroundColor: activeColor + (isActive ? '20' : '10'),
  },

  '&:not(:last-child)': {
    borderRight: 'none',
  },

  '&:first-child': {
    borderRadius: '16px 0 0 16px',
  },

  '&:last-child': {
    borderRadius: '0 16px 16px 0',
  },
}));

const MonthContainer = styled(Box)(() => ({
  boxSizing: 'border-box',
  borderRadius: '16px',
  background: 'white',
  border: '1px solid rgba(27, 47, 43, 0.10)',

  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
}));

const MonthHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 24px 24px 36px',
  alignItems: 'center',
  gap: '20px',
  alignSelf: 'stretch',
}));

const MonthBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 24px 24px 24px',
  alignItems: 'flex-start',
  gap: '24px',
}));

const BottomGraphs = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '24px',
}));
