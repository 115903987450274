import { Typography } from '@mui/material';
import React from 'react';

const NoData = () => {
  return (
    <Typography
      sx={{
        padding: '50px 0',
      }}
    >
      No admin data yet
    </Typography>
  );
};

export default NoData;
