import React, { useEffect, useRef, useState } from 'react';

import BootstrapTooltip from '../customTooltip';
import { Typography } from '@mui/material';

export default function Referrer({ row, name }) {
  const [shouldRenderTooltip, setShouldRenderTooltip] = useState(false);

  const domain = row[name]?.replace(/^(https?:\/\/)?(www\.)?/i, '');

  // Удаление последнего слеша и всего, что после него
  const index = domain?.indexOf('/');
  const result = index === -1 ? domain : domain?.slice(0, index);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      if ([100, 166].includes(ref.current.clientWidth)) {
        setShouldRenderTooltip(true);
      }
    }
  }, [ref]);

  if (
    row.is_view === true &&
    result?.indexOf('barcelonaweedmap.com') === -1 &&
    result?.indexOf('cannabisbcn.com') === -1
  ) {
    return shouldRenderTooltip ? (
      <BootstrapTooltip title={result}>
        <Typography
          variant="table-text"
          noWrap
          sx={{
            color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
            '&::after': {
              content: "''",
              display: 'block',
            },
          }}
        >
          {result}
        </Typography>
      </BootstrapTooltip>
    ) : (
      <Typography
        variant="table-text"
        noWrap
        ref={ref}
        sx={{
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
          '&::after': {
            content: "''",
            display: 'block',
          },
        }}
      >
        {result}
      </Typography>
    );
  } else {
    return null;
  }
}
