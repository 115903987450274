import React from 'react';
import CustomPopover from './customPopover';
import { Typography, styled } from '@mui/material';

const PiePopover = ({ hoveredSectorData = {}, isPiePopoverOpen, ...props }) => {
  const percent = hoveredSectorData.value > 0 ? hoveredSectorData.value : '< 1';

  return (
    <CustomPopover {...props} isOpen={isPiePopoverOpen}>
      <Text>{hoveredSectorData.name}</Text>
      <Text sx={{ fontWeight: 700, marginTop: 0.5 }}>
        {hoveredSectorData.number} | {percent}%
      </Text>
    </CustomPopover>
  );
};

export default PiePopover;

const Text = styled(Typography)(({ theme }) => ({
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
}));
