import { Box, styled } from '@mui/material';
import React from 'react';

const Popup = ({ isOpen, handleClose, children, containerStyles }) => {
  function handleWrapperClick(evt) {
    if (evt.target.id === 'wrapper') {
      handleClose();
    }
  }

  return (
    <Wrapper isOpen={isOpen} onClick={handleWrapperClick} id="wrapper">
      <Container containerStyles={containerStyles || {}}>{children}</Container>
    </Wrapper>
  );
};

export default Popup;

const Wrapper = styled(Box)(({ isOpen }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,

  padding: 50,

  backgroundColor: '#00000080',
  transition: 'visibility 0.3s, opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)',

  visibility: isOpen ? 'visible' : 'hidden',
  opacity: isOpen ? 1 : 0,
}));

const Container = styled(Box)(({ isOpen, containerStyles }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  minWidth: 400,
  minHeight: 400,

  borderRadius: 10,
  backgroundColor: 'white',
  overflow: 'auto',

  ...containerStyles,
}));
