import React from 'react';
import { Typography } from '@mui/material';
import BootstrapTooltip from '../../table/customTooltip';

export default function IP({ row }) {
  const ip = row.last_login_IP_info?.ip || '';
  const shouldRenderTooltip = ip.length > 15;

  if (!ip) {
    return null;
  }

  return shouldRenderTooltip ? (
    <BootstrapTooltip title={ip}>
      <Typography variant="table-text-ellipsis">{ip}</Typography>
    </BootstrapTooltip>
  ) : (
    <Typography variant="table-text">{ip}</Typography>
  );
}
