import { Box, styled } from '@mui/material';
import React from 'react';

const Loading = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default Loading;

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Loader = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  border: '4px solid #1B2F2B',
  borderBottomColor: 'transparent',
  borderRadius: '50%',
  display: 'inline-block',
  boxSizing: 'border-box',
  animation: 'rotation 1s linear infinite',

  '@keyframes rotation': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
}));
