import React from 'react';
import { colorByTitle } from './consts';
import { getWeekNumber } from './utils/date';

const weekNumber = getWeekNumber();
const currentYear = new Date().getFullYear();

const CustomDot = ({ cx, cy, title, payload, year }) => {
  const size = 1.8;

  return (payload.title <= weekNumber - 1 && year === currentYear) || year < currentYear ? (
    <svg x={cx - size} y={cy - size} width={size * 2} height={size * 2} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={size}
        cy={size}
        r={size - 0.5}
        strokeWidth={0.5}
        transform={`matrix(1 0 0 -1 0 ${size * 2})`}
        fill={colorByTitle[title].fill}
        stroke={colorByTitle[title].stroke}
      />
    </svg>
  ) : null;
};

export default CustomDot;
