export const headCellsColors = {
  blue: '#D4F5FF',
  green: '#D8F2D9',
  red: '#FFDADA',
  orange: '#FFE3CA',
};

export const colorByTitle = {
  req: {
    fill: '#A3A2ED',
    stroke: '#1E1BD0',
  },
  vis: {
    fill: '#EDA2A2',
    stroke: '#D01B1B',
  },
  reg: {
    fill: '#9CC19E',
    stroke: '#0A6C0E',
  },
  req_prev_year: {
    fill: '#A3A2ED',
    stroke: '#1E1BD0',
  },
  vis_prev_year: {
    fill: '#EDA2A2',
    stroke: '#D01B1B',
  },
  reg_prev_year: {
    fill: '#9CC19E',
    stroke: '#0A6C0E',
  },
  cr: {
    fill: '#86D6B5',
    bgFill: '#86D6B5',
    stroke: '#109961',
  },
};

export const graphs = [
  {
    title: 'vis',
  },
  {
    title: 'req',
  },
  {
    title: 'reg',
  },
  {
    title: 'vis_prev_year',
  },
  {
    title: 'req_prev_year',
  },
  {
    title: 'reg_prev_year',
  },
];
