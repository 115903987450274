import { Typography } from '@mui/material';
import React from 'react';

export default function Br({ row, name }) {
  if (row.is_view === true) {
    return (
      <Typography
        variant="table-text"
        sx={{
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
        }}
      >
        {row[name].toLowerCase()}
      </Typography>
    );
  } else {
    return <></>;
  }
}
