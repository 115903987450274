import { Button, styled } from '@mui/material';
import React from 'react';

const ClubSelect = styled(Button)(({ isStaff }) => ({
  height: 30,
  padding: '0 10px',
  borderRadius: 16,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,

  fontSize: 12,
  textTransform: 'uppercase',

  transition: `background-color .1s linear`,
  cursor: 'pointer',
}));

const Status = ({ value, handleSelectClick, userId }) => {
  return (
    <ClubSelect
      id="basic-status-button"
      aria-controls="basic-menu"
      aria-haspopup="true"
      aria-expanded="true"
      variant={value === 'blocked' ? 'action-red' : 'action'}
      onClick={handleSelectClick}
      data-userid={userId}
      isBlocked={value === 'blocked'}
    >
      {value}
    </ClubSelect>
  );
};

export default Status;
