import { createSearchParams } from 'react-router-dom';
import { axiosInstance } from './axiosInstanse';

export const getAdmins = () => {
  return axiosInstance.get(`admins/`);
};

export const CreateAdmin = (data) => {
  return axiosInstance.post(`admins/`, data);
};

export const UpdateAdmin = (data) => {
  return axiosInstance.patch(`admins/`, data);
};

export const DeleteAdmin = (data) => {
  return axiosInstance.delete(`admins/${data.id}/`);
};
