export function transformStatsForRechart(stats, year) {
  const transformedStats = {};

  for (let monthNumber in stats) {
    const monthData = stats[monthNumber];

    transformedStats[monthNumber] = {
      lang_browser: [],
      lang_website: [],
      days: []
    };

    Object.entries(monthData.lang_browser).forEach(([langKey, value]) => {
      transformedStats[monthNumber].lang_browser.push({
        name: langKey ? langKey.toUpperCase() : 'Unknown',
        value: value,
      });
    });

    Object.entries(monthData.lang_website).forEach(([langKey, value]) => {
      transformedStats[monthNumber].lang_website.push({
        name: langKey ? langKey.toLowerCase() : 'Unknown',
        value: value,
      });
    });

    const numberOfDaysInMonth = new Date(year, monthNumber, 0).getDate();

    // Статистика по дням
    for (let i = 0; i < numberOfDaysInMonth; i += 1) {
      const key = (i + 1).toString().padStart(2, '0');

      const currentDay = { name: key };
      transformedStats[monthNumber].days.push(currentDay);

      if (monthData.days.by_created?.[key]) {
        currentDay.by_created_req = monthData.days.by_created[key].orders;
        currentDay.by_created_vis = monthData.days.by_created[key].visitors;

      } else {
        currentDay.by_created_req = 0;
        currentDay.by_created_vis = 0;
      }

      if (monthData.days.by_visit?.[key]) {
        currentDay.by_visit_req = monthData.days.by_visit[key].orders;
        currentDay.by_visit_vis = monthData.days.by_visit[key].visitors;

      } else {
        currentDay.by_visit_req = 0;
        currentDay.by_visit_vis = 0;
      }
    }

    transformedStats[monthNumber].lang_browser.sort((a, b) => b.value - a.value);
    transformedStats[monthNumber].lang_website.sort((a, b) => b.value - a.value);
  }

  return transformedStats;
}

export function getMaxValuesByMonths(stats) {
  // Count maximum values for lang_browser and lang_website for each month
  const maxValues = {};

  for (let monthNumber in stats) {
    const monthData = stats[monthNumber];

    const maxLangBrowserValue = monthData.lang_browser.reduce(
      (acc, countryData) => (countryData.value > acc ? countryData.value : acc),
      0,
    );
    const maxLangWebsiteValue = monthData.lang_website.reduce(
      (acc, countryData) => (countryData.value > acc ? countryData.value : acc),
      0,
    );
    const maxDaysValueByCreated = monthData.days.by_created?.reduce(
      (acc, dayData) => (dayData.value > acc ? dayData.value : acc),
      0,
    );
    const maxDaysValueByVisit = monthData.days.by_visit?.reduce(
      (acc, dayData) => (dayData.value > acc ? dayData.value : acc),
      0,
    );

    maxValues[monthNumber] = {
      lang_browser: maxLangBrowserValue,
      lang_website: maxLangWebsiteValue,
      days: {
        by_created: maxDaysValueByCreated,
        by_visit: maxDaysValueByVisit,
      },
    };
  }

  return maxValues;
}

export function getSumOfValuesByMonth(stats) {
  // Count sum of values for lang_browser and lang_website for each month
  const sumOfValues = {};

  for (let monthNumber in stats) {
    const monthData = stats[monthNumber];

    const sumOfLangBrowserValue = monthData.lang_browser.reduce((acc, countryData) => countryData.value + acc, 0);
    const sumOfLangWebsiteValue = monthData.lang_website.reduce((acc, countryData) => countryData.value + acc, 0);

    sumOfValues[monthNumber] = {
      lang_browser: sumOfLangBrowserValue,
      lang_website: sumOfLangWebsiteValue,
    };
  }

  return sumOfValues;
}
