export function getYears() {
  const startYear = 2024;
  const endYear = 2029;

  return new Array(endYear - startYear + 1).fill(0).map((_, index) => startYear + index);
}

export function getLastSundays() {
  // Функция для получения даты последнего воскресенья марта и октября
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();

  // Находим последнее воскресенье марта
  var lastSundayMarch = new Date(currentYear, 2, 31, 2); // Последний день марта
  while (lastSundayMarch.getDay() !== 0) {
    // Пока это не воскресенье
    lastSundayMarch.setDate(lastSundayMarch.getDate() - 1); // Перемещаемся на предыдущий день
  }

  // Находим последнее воскресенье октября
  var lastSundayOctober = new Date(currentYear, 9, 31, 2); // Последний день октября
  while (lastSundayOctober.getDay() !== 0) {
    // Пока это не воскресенье
    lastSundayOctober.setDate(lastSundayOctober.getDate() - 1); // Перемещаемся на предыдущий день
  }

  return [lastSundayMarch, lastSundayOctober];
}
