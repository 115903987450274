import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import TablePage from '../pages/table';
import MembershipFormPage from '../pages/form';
import LoginPage from '../pages/login';
import CustomRouter from './customRouter';
import customHistory from './history';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isUserLoggedAtom } from '../components/atoms/isUserLogged';
import StatisticPage from '../components/statistic';
import Admins from '../components/admins';
import AnalyticsPage from '../components/analytics';
export function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function MainRoute() {
  const [, setIsUserLogged] = useRecoilState(isUserLoggedAtom);

  if (localStorage.getItem('access-token')) {
    setIsUserLogged(true);
  }

  return (
    <CustomRouter history={customHistory}>
      {/* <ScrollToTop /> */}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <RequestAuth>
              <TablePage />
            </RequestAuth>
          }
        />
        <Route
          exact
          path="/statistic/lang"
          element={
            <RequestAuth>
              <StatisticPage />
            </RequestAuth>
          }
        />
        <Route
          exact
          path="/analytics"
          element={
            <RequestAuth>
              <AnalyticsPage />
            </RequestAuth>
          }
        />
        <Route
          exact
          path="/admins"
          element={
            <RequestAuth>
              <Admins />
            </RequestAuth>
          }
        />
        <Route
          exact
          path="/:page"
          element={
            <RequestAuth>
              <TablePage />
            </RequestAuth>
          }
        />
        <Route
          path="/form"
          element={
            <RequestAuth>
              <MembershipFormPage />
            </RequestAuth>
          }
        />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </CustomRouter>
  );
}

const RequestAuth = ({ children }) => {
  const isUserLogged = useRecoilValue(isUserLoggedAtom);

  if (isUserLogged) {
    return children;
  } else {
    return <Navigate to={'/login'} />;
  }
};
