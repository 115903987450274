import React from 'react';

import BootstrapTooltip from '../customTooltip';

export default function Country({ row, name }) {
  if (row.is_view === true) {
    return (
      <BootstrapTooltip title={row.ip.country}>
        <img
          src={`/django${row.ip.image}`}
          alt={row.ip.country}
          style={{
            height: 24,
            width: 24,
            margin: '0',
            borderRadius: 100,
            border: '1px solid rgba(27, 47, 43, 0.10)',
          }}
        />
      </BootstrapTooltip>
    );
  } else {
    return <></>;
  }
}
