import { createTheme } from '@mui/material/styles';
import { teal, blue } from '@mui/material/colors';

const commonButtonStyles = {
  boxSizing: 'border-box',
  padding: '5px 9px',
  borderRadius: '22px',

  minWidth: 0,

  wordWrap: 'break-word',

  backgroundColor: 'transparent',

  textTransform: 'capitalize',
  fontWeight: 500,
  lineHeight: 1,

  '@media (max-width: 1440px)': {
    padding: '4px 9px',
  },
};

const commonChipStyles = {
  fontFamily: 'Gilroy',
  fontSize: 14,
  fontWeight: 1000,
  lineHeight: 1,
  textTransform: 'uppercase',

  height: 26,

  '& .MuiChip-label': {
    paddingLeft: 10,
    paddingRight: 10,
  },
};

const commonActionButtonStyles = {
  height: 36,
  padding: '0 24p',
  fontSize: '12px',
  fontWeight: 1000,
  lineHeight: '1',
  textTransform: 'uppercase',
  borderRadius: 40,

  '&:disabled': {
    backgroundColor: '#aaa',
    opacity: 0.6,
  },
};

let theme = createTheme({
  typography: {
    fontFamily: ['Gilroy', 'sans-serif'].join(','),
    color: '#1B2F2B',
  },
  palette: {
    primary: {
      main: '#0FBE76',
    },
    secondary: {
      main: 'rgba(243, 239, 232, 0.8)',
    },
    background: {
      default: 'white',
      paper: '#F3EFE8',
    },
    black: {
      main: '#1B2F2B',
      secondary: '#434343',
    },
    green: '#10BE76',
    red: '#FF5D3D',
  },
  transitions: {
    cubic: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'action' },
          style: {
            ...commonActionButtonStyles,
            color: '#1B2F2B',
            backgroundColor: '#0FBE76',

            '&:hover': {
              backgroundColor: '#0FAE66',
            },

            '@media (max-width: 1440px)': {
              height: 30,
            },
          },
        },
        {
          props: { variant: 'action-red' },
          style: {
            ...commonActionButtonStyles,
            color: 'white',
            backgroundColor: '#DE2929',

            '&:hover': {
              backgroundColor: '#CE1919',
            },
          },
        },
        {
          props: { variant: 'circle' },
          style: {
            boxSizing: 'border-box',
            minWidth: 'unset',
            width: 36,
            height: 36,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,

            backgroundColor: 'transparent',
            borderRadius: 20,
            border: '1px solid rgba(27, 47, 43, 0.10)',
            backdropFilter: 'blur(10px)',

            cursor: 'pointer',

            '@media (max-width: 1440px)': {
              height: 30,
            },
          },
        },
        {
          props: { variant: 'All' },
          style: {
            ...commonButtonStyles,
            color: '#10ACA3',
            backgroundColor: 'rgba(16, 172, 163, 0.10)',
            border: `1px solid rgba(16, 172, 163, 0.10)`,

            '&:hover': {
              backgroundColor: 'rgba(16, 172, 163, 0.20)',
            },
          },
        },
        {
          props: { variant: 'Today' },
          style: {
            ...commonButtonStyles,
            color: '#4164E1',
            backgroundColor: 'rgba(65, 100, 225, 0.10)',
            border: `1px solid rgba(65, 100, 225, 0.10)`,

            '&:hover': {
              backgroundColor: 'rgba(65, 100, 225, 0.20)',
            },
          },
        },
        {
          props: { variant: 'Expired' },
          style: {
            ...commonButtonStyles,
            color: '#DE2929',
            backgroundColor: 'rgba(222, 41, 41, 0.10)',
            border: `1px solid rgba(222, 41, 41, 0.10)`,

            '&:hover': {
              backgroundColor: 'rgba(222, 41, 41, 0.20)',
            },
          },
        },
        {
          props: { variant: 'New' },
          style: {
            ...commonButtonStyles,
            color: '#1782E4',
            backgroundColor: 'rgba(23, 130, 228, 0.10)',
            border: `1px solid rgba(23, 130, 228, 0.10)`,

            position: 'relative',

            paddingLeft: '19px !important',

            '&:hover': {
              backgroundColor: 'rgba(23, 130, 228, 0.20)',
            },

            '&::before': {
              content: '""',
              position: 'absolute',
              left: 9,
              top: '50%',

              width: 6,
              height: 6,
              backgroundColor: '#1782E4',

              borderRadius: 10,

              transform: 'translateY(-50%)',
            },

            '@media (max-width: 1440px)': {
              '&::before': {
                top: 8,
              },
            },
          },
        },
        {
          props: { variant: 'Registered' },
          style: {
            ...commonButtonStyles,
            color: '#10AC61',
            backgroundColor: '#E8F8F0',
            border: `1px solid rgba(16, 172, 97, 0.10)`,

            '&:hover': {
              backgroundColor: '#D0F0D8',
            },

            '&:disabled': {
              color: '#10AC61',
            },
          },
        },
        {
          props: { variant: 'Booked' },
          style: {
            ...commonButtonStyles,
            color: teal[500],
            backgroundColor: 'rgba(16, 172, 163, 0.10)',
            border: `1px solid ${teal[100]}`,

            '&:hover': {
              backgroundColor: 'rgba(16, 172, 163, 0.20)',
            },
          },
        },
        {
          props: { variant: 'Done' },
          style: {
            ...commonButtonStyles,
            color: '#1B2F2B',
            backgroundColor: 'rgba(27, 47, 43, 0.10)',
            border: `1px solid rgba(27, 47, 43, 0.10)`,

            '&:hover': {
              backgroundColor: 'rgba(27, 47, 43, 0.20)',
            },
          },
        },
        {
          props: { variant: 'Hidden' },
          style: {
            ...commonButtonStyles,
            color: 'black',
            backgroundColor: 'rgba(16, 172, 163, 0.10)',
            border: '1px solid rgba(39, 21, 92, 0.1)',

            '&:hover': {
              backgroundColor: 'rgba(16, 172, 163, 0.20)',
            },
          },
        },
        {
          props: { variant: 'Deleted' },
          style: {
            ...commonButtonStyles,
            color: 'black',
            backgroundColor: 'rgba(16, 172, 163, 0.10)',
            border: '1px solid rgba(39, 21, 92, 0.1)',

            '&:hover': {
              backgroundColor: 'rgba(16, 172, 163, 0.20)',
            },
          },
        },
        {
          props: { variant: 'club' },
          style: {
            ...commonChipStyles,
            color: 'black',
            border: `1px solid ${blue[100]}`,
            background: teal[400],
          },
        },
      ],
    },
    MuiModal: {
      styleOverrides: {
        backdrop: {
          // backdropFilter: "blur(2px)",
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'club' },
          style: {
            ...commonChipStyles,
          },
        },
      ],
    },
    MuiBox: {
      variants: [
        {
          props: { variant: 'green' },
          style: {
            background: '#10BE76',
            color: '#FFF',
          },
        },
        {
          props: { variant: 'red' },
          style: {
            background: '#FF5D3D',
            color: '#FFF',
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'bold' },
          style: {
            color: '#1B2F2B',
            fontFamily: 'Gilroy',
            fontSize: '20px',
            fontWeight: 1000,
            lineHeight: 1,
            textTransform: 'uppercase',
          },
        },
        {
          props: { variant: 'table-text' },
          style: {
            color: 'rgba(27, 47, 43, 0.80)',
            fontFamily: 'Gilroy',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1,

            '@media (max-width: 1440px)': {
              fontSize: 12,
            },
          },
        },
        {
          props: { variant: 'table-text-ellipsis' },
          style: {
            color: 'rgba(27, 47, 43, 0.80)',
            fontFamily: 'Gilroy',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',

            '@media (max-width: 1440px)': {
              fontSize: 12,
            },
          },
        },
        {
          props: { variant: 'popup-title' },
          style: {
            color: '#1B2F2B',
            fontFamily: 'Gilroy',
            fontSize: '24px',
            fontWeight: 1000,
            lineHeight: 1,
            textTransform: 'uppercase',
          },
        },
      ],
    },
  },
});

export default theme;
